import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221')
];

export const server_loads = [0,27,24,4,6,7,10,14];

export const dictionary = {
		"/(marketing)": [154,[18]],
		"/(marketing)/about": [155,[18,19]],
		"/(admin)/admin": [36,[2]],
		"/(admin)/admin/abundant/add-update": [~37,[2]],
		"/(admin)/admin/abundant/backup-to-cloud-storage": [~38,[2]],
		"/(admin)/admin/abundant/document-validation": [~39,[2]],
		"/(admin)/admin/abundant/duplicate-risk-type": [~40,[2]],
		"/(admin)/admin/abundant/evict-file-cache": [~41,[2]],
		"/(admin)/admin/abundant/groups": [~42,[2]],
		"/(admin)/admin/abundant/migrate-db": [~43,[2]],
		"/(admin)/admin/abundant/open-api": [44,[2]],
		"/(admin)/admin/abundant/queries-sg": [~45,[2]],
		"/(admin)/admin/abundant/reset-db": [~46,[2]],
		"/(admin)/admin/abundant/sync-indexes-to-db": [~47,[2]],
		"/(admin)/admin/abundant/sync-indexes-to-disk": [~48,[2]],
		"/(admin)/admin/abundant/sync-validation-to-db": [~49,[2]],
		"/(admin)/admin/ai/extract-recommendations": [~50,[2]],
		"/(admin)/admin/categories/add": [~51,[2]],
		"/(admin)/admin/charts/bar": [~52,[2]],
		"/(admin)/admin/charts/map": [53,[2]],
		"/(admin)/admin/dev-ops/analytics.calendar-month": [~55,[2]],
		"/(admin)/admin/dev-ops/analytics.calendar": [~54,[2]],
		"/(admin)/admin/dev-ops/analytics.items-full-load": [~56,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-month-full-load": [~57,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-month-incremental-load": [~58,[2]],
		"/(admin)/admin/dev-ops/find-unused-files": [~59,[2]],
		"/(admin)/admin/dev-ops/run-pipelines": [~60,[2]],
		"/(admin)/admin/insurance/import-arla": [~61,[2]],
		"/(admin)/admin/misc/add-analytics-board": [~62,[2]],
		"/(admin)/admin/misc/create-thumbnails-by-id": [~63,[2]],
		"/(admin)/admin/misc/customer-scripts": [~64,[2]],
		"/(admin)/admin/misc/get-asset-info": [~65,[2]],
		"/(admin)/admin/misc/inspect-devalue": [66,[2]],
		"/(admin)/admin/misc/sendgrid-delete-contacts": [~67,[2]],
		"/(admin)/admin/risk-data/copy-risk-model-to-prod": [~68,[2]],
		"/(admin)/admin/risk-data/copy-risk-type": [~69,[2]],
		"/(admin)/admin/test-email": [~70,[2]],
		"/(admin)/admin/users/assign-permissions": [~71,[2]],
		"/(admin)/admin/users/delete": [~72,[2]],
		"/(admin)/admin/users/end-session": [~73,[2]],
		"/(admin)/admin/users/seed": [~74,[2]],
		"/(admin)/admin/users/set-user-tasks": [~75,[2]],
		"/(admin)/admin/users/toggle-21risk-admin": [~76,[2]],
		"/(marketing)/blog": [156,[18,20]],
		"/(marketing)/blog/[slug]": [157,[18,20]],
		"/(marketing)/changelog": [158,[18]],
		"/(marketing)/changelog/[slug]": [159,[18]],
		"/(marketing)/checklists": [~160,[18]],
		"/(marketing)/checklists/success": [164,[18]],
		"/(marketing)/checklists/view": [165,[18]],
		"/(marketing)/checklists/[_id=objectId]": [~161,[18]],
		"/(marketing)/checklists/[_id=objectId]/print": [~162,[18]],
		"/(marketing)/checklists/[_id=objectId]/request": [~163,[18]],
		"/(docs)/compliance": [148,[17]],
		"/(docs)/compliance/[...rest]": [~149,[17]],
		"/(marketing)/contact": [166,[18]],
		"/(marketing)/contact/sales": [~167,[18]],
		"/(marketing)/contact/sales/success": [168,[18]],
		"/(marketing)/customers": [169,[18,21]],
		"/(marketing)/customers/BHJ": [170,[18,21]],
		"/(marketing)/customers/[slug]": [171,[18,21]],
		"/(docs)/docs": [150,[17]],
		"/(docs)/docs/concepts": [152,[17]],
		"/(docs)/docs/concepts/[...rest]": [153,[17]],
		"/(docs)/docs/[...rest]": [~151,[17]],
		"/(reset)/emails/preview/[templateId]/[sendgridPayload]": [~205,[27]],
		"/(marketing)/experts": [172,[18,22]],
		"/(marketing)/experts/[slug]": [~173,[18,22,23]],
		"/(marketing)/experts/[slug]/success": [174,[18,22,23]],
		"/(marketing)/features/actions": [175,[18]],
		"/(marketing)/features/audits": [176,[18]],
		"/(unauthed)/feedback/emails/thank-you": [212,[30,31]],
		"/(unauthed)/feedback/emails/[emailId]": [~211,[30,31]],
		"/(marketing)/health": [177,[18]],
		"/(marketing)/help": [~178,[18,24]],
		"/(marketing)/help/success": [179,[18,24]],
		"/(marketing)/legal": [180,[18]],
		"/(marketing)/legal/[slug]": [181,[18]],
		"/(unauthed)/library-public": [213,[30]],
		"/(unauthed-prerender)/login": [~217,[32,33]],
		"/(unauthed-prerender)/login/check": [~218,[32,33]],
		"/(app)/logout": [~146],
		"/(admin)/mgmt": [77,[3]],
		"/(admin)/mgmt/analytics/overview": [~78,[3]],
		"/(unauthed)/misc/debug": [214,[30]],
		"/(unauthed)/misc/files-list": [~215,[30]],
		"/(marketing)/newsletter/check": [182,[18]],
		"/(marketing)/newsletter/subscribe": [~183,[18]],
		"/(marketing)/newsletter/success": [184,[18]],
		"/(marketing)/newsletter/verify": [~185,[18]],
		"/(unauthed)/no-access/[tenant_without_access=org_slug]": [~216,[30]],
		"/(reset)/onboarding": [206,[27,29]],
		"/(reset)/onboarding/1": [207,[27,29]],
		"/(reset)/onboarding/2": [~208,[27,29]],
		"/(reset)/onboarding/3": [~209,[27,29]],
		"/(reset)/onboarding/4": [210,[27,29]],
		"/(marketing)/partners": [~186,[18]],
		"/(marketing)/partners/success": [187,[18]],
		"/(marketing)/pricing": [188,[18]],
		"/(app)/report/[_id=objectId]": [~147],
		"/(marketing)/roadmap": [189,[18]],
		"/(unauthed-prerender)/signup": [~219,[32,34]],
		"/(unauthed-prerender)/signup/check": [~220,[32,34]],
		"/(unauthed-prerender)/signup/complete": [~221,[32,34]],
		"/(marketing)/solutions/compliance": [190,[18]],
		"/(marketing)/solutions/insurance-values": [191,[18]],
		"/(marketing)/solutions/natcat": [192,[18]],
		"/(marketing)/solutions/natcat/[hazard]": [~193,[18]],
		"/(marketing)/solutions/property-insurance": [~194,[18]],
		"/(marketing)/solutions/property-insurance/roi-breakdown": [~195,[18]],
		"/(marketing)/solutions/property-insurance/roi-breakdown/success": [196,[18]],
		"/(marketing)/solutions/property-insurance/roi-calculator": [~197,[18]],
		"/(marketing)/updates": [~198,[18]],
		"/(marketing)/updates/[slug]": [~199,[18]],
		"/(marketing)/whitepapers": [200,[18,25]],
		"/(marketing)/whitepapers/success": [202,[18,25]],
		"/(marketing)/whitepapers/view/[uuid]": [203,[18,25]],
		"/(marketing)/whitepapers/[slug]": [~201,[18,25,26]],
		"/(app)/[tenant=org_slug]": [80,[4]],
		"/(app)/[tenant=org_slug]/actions": [~82,[4]],
		"/(app)/[tenant=org_slug]/actions/board": [84,[4]],
		"/(app)/[tenant=org_slug]/actions/table": [85,[4]],
		"/(app)/[tenant=org_slug]/actions/[view]": [83,[4]],
		"/(app)/[tenant=org_slug]/action/[itemId]": [~81,[4]],
		"/(app)/[tenant=org_slug]/analytics/[board=board_slug]/[[page]]": [~86,[4]],
		"/(app)/[tenant=org_slug]/compliance": [87,[4]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]": [88,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions": [89,[4,5,6]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/board": [90,[4,5,6]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/settings": [91,[4,5,6,7]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/settings/fields/new": [~93,[4,5,6,7]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/settings/fields/[field]": [~92,[4,5,6,7]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/actions/table": [~94,[4,5,6]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/analytics": [~95,[4,5,8]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/analytics/empty": [96,[4,5,8]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit": [~97,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/automations/new": [~103,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/automations/[_id=objectId]": [~102,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/[version]": [~98,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/[version]/automations": [~99,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/[version]/automations/new": [~101,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/edit/[version]/automations/[_id=objectId]": [~100,[4,5]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports": [~104,[4,5,9]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/new": [~107,[4,5,9]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/request-audit": [~108,[4,5,9]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/settings": [109,[4,5,9,10]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/settings/fields/new": [~111,[4,5,9,10]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/settings/fields/[field]": [~110,[4,5,9,10]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/[_id=objectId]": [~105,[4,5,9]],
		"/(app)/[tenant=org_slug]/compliance/[board=board_slug]/reports/[_id=objectId]/qr-code": [~106,[4,5,9]],
		"/(app)/[tenant=org_slug]/file-upload": [~112,[4]],
		"/(app)/[tenant=org_slug]/folder/[board=board_slug]": [113,[4,11]],
		"/(app)/[tenant=org_slug]/folder/[board=board_slug]/edit": [~114,[4,11]],
		"/(app)/[tenant=org_slug]/getting-started": [~115,[4]],
		"/(app)/[tenant=org_slug]/home": [~116,[4]],
		"/(app)/[tenant=org_slug]/insurance/map": [117,[4]],
		"/(app)/[tenant=org_slug]/insurance/settings": [~118,[4]],
		"/(app)/[tenant=org_slug]/insurance/settings/[period]": [~119,[4]],
		"/(app)/[tenant=org_slug]/insurance/table": [120,[4]],
		"/(app)/[tenant=org_slug]/insurance/table/[period]": [~121,[4]],
		"/(app)/[tenant=org_slug]/insurance/table/[period]/[siteId=objectId]": [~122,[4]],
		"/(app)/[tenant=org_slug]/new/board": [~123,[4]],
		"/(app)/[tenant=org_slug]/new/folder": [~124,[4]],
		"/(app)/[tenant=org_slug]/new/model": [~125,[4]],
		"/(reset)/[tenant=org_slug]/print/[_id=objectId]": [~204,[27,28]],
		"/(app)/[tenant=org_slug]/reports": [~126,[4]],
		"/(app)/[tenant=org_slug]/reports/[view]": [127,[4]],
		"/(app)/[tenant=org_slug]/settings/account": [~128,[4,12]],
		"/(app)/[tenant=org_slug]/settings/account/balance-changes": [~129,[4,12]],
		"/(app)/[tenant=org_slug]/settings/account/roles/[roleId]": [~130,[4,12]],
		"/(app)/[tenant=org_slug]/settings/profile": [~131,[4,12]],
		"/(app)/[tenant=org_slug]/sites": [~132,[4,13]],
		"/(app)/[tenant=org_slug]/sites/import": [~134,[4,13]],
		"/(app)/[tenant=org_slug]/sites/map": [~135,[4,13]],
		"/(app)/[tenant=org_slug]/sites/settings": [136,[4,13,14]],
		"/(app)/[tenant=org_slug]/sites/settings/fields/new": [~138,[4,13,14]],
		"/(app)/[tenant=org_slug]/sites/settings/fields/[field]": [~137,[4,13,14]],
		"/(app)/[tenant=org_slug]/sites/table": [~139,[4,13]],
		"/(app)/[tenant=org_slug]/sites/tree": [~140,[4,13]],
		"/(app)/[tenant=org_slug]/sites/[siteId=objectId]": [~133,[4,13]],
		"/(app)/[tenant=org_slug]/tags": [~141,[4,15]],
		"/(app)/[tenant=org_slug]/tags/[tagId=objectId]": [~142,[4,15]],
		"/(app)/[tenant=org_slug]/users": [~143,[4,16]],
		"/(app)/[tenant=org_slug]/users/new": [~145,[4,16]],
		"/(app)/[tenant=org_slug]/users/[user_id]": [~144,[4,16]],
		"/(app)/[...rest]": [~79]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: universal_hooks.reroute || (() => {}),
	transport: universal_hooks.transport || {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';